var EnvironmentManager = /** @class */ (function () {
    function EnvironmentManager(scene) {
        this.obstacles = [];
        this.safezones = [];
        this.scene = scene;
    }
    EnvironmentManager.prototype.init = function () {
        this.obstacles = [];
        this.safezones = [];
    };
    EnvironmentManager.prototype.create = function (levelConfig) {
        this.createObstacles(levelConfig);
        this.createSafezones(levelConfig);
    };
    EnvironmentManager.prototype.createObstacles = function (levelConfig) {
        var _this = this;
        console.log("Creating obstacles");
        levelConfig.obstacles.forEach(function (obstacleConfig) {
            console.log("Creating obstacle", obstacleConfig);
            var newObstacle = _this.scene.add.rectangle(obstacleConfig.x, obstacleConfig.y, obstacleConfig.width, obstacleConfig.height, 0x000000);
            _this.scene.physics.add.existing(newObstacle, true);
            _this.obstacles.push(newObstacle);
        });
    };
    EnvironmentManager.prototype.getObstacles = function () {
        return this.obstacles;
    };
    EnvironmentManager.prototype.createSafezones = function (levelConfig) {
        var bottomWall = this.scene.add.rectangle(this.scene.scale.width / 2, this.scene.scale.height - 10, this.scene.scale.width, 20, 0x000000);
        this.scene.physics.add.existing(bottomWall, true);
        this.safezones.push(bottomWall);
        var text = this.scene.add.text(this.scene.scale.width / 2, this.scene.scale.height - 10, "Ant Safe Zone", {
            fontSize: "20px",
            color: "#ffffff",
        });
        text.setOrigin(0.5, 0.5);
    };
    EnvironmentManager.prototype.getSafezones = function () {
        return this.safezones;
    };
    return EnvironmentManager;
}());
export default EnvironmentManager;
