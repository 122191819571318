var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Phaser from "phaser";
import UIManager from "../GameManagement/UIManager";
import { SCENES } from "../config";
import GameManager from "../GameManagement/GameManager";
var EndScene = /** @class */ (function (_super) {
    __extends(EndScene, _super);
    function EndScene() {
        var _this = _super.call(this, SCENES.END_SCENE) || this;
        _this.gameManager = new GameManager(_this);
        _this.uiManager = new UIManager(_this, _this.gameManager);
        return _this;
    }
    EndScene.prototype.preload = function () {
        // Load any assets for the end screen here if needed
    };
    EndScene.prototype.create = function (_a) {
        var _this = this;
        var score = _a.score;
        this.add
            .text(300, 300, "Congratulations!", {
            fontSize: "48px",
            color: "#000000",
        })
            .setOrigin(0.5);
        this.add
            .text(300, 400, "Ants Saved: ".concat(score), {
            fontSize: "36px",
            color: "#000000",
        })
            .setOrigin(0.5);
        this.uiManager.createButton(300, 500, "Play Again", function () {
            _this.scene.start(SCENES.TITLE_SCENE);
        });
    };
    return EndScene;
}(Phaser.Scene));
export default EndScene;
