import { LEVELS } from "../Services/levels.service";
import { CUSTOM_EVENTS } from "../config";
var GameStateOptions = [
    "IN_PROGRESS",
    "PAUSED",
    "CURRENT_LEVEL_COMPLETED",
    "NEXT_LEVEL_READY",
    "GAME_COMPLETED",
];
var GameManager = /** @class */ (function () {
    function GameManager(scene) {
        this.allTimeScore = 0;
        this.currentLevelScore = 0;
        this.gameState = "PAUSED";
        this.scene = scene;
        this.currentLevel = 0;
    }
    GameManager.prototype.init = function () {
        this.updateGameState("PAUSED");
        this.currentLevelScore = 0;
        this.levelConfig = LEVELS[this.currentLevel];
    };
    GameManager.prototype.create = function () {
        // this.setupUI();
    };
    GameManager.prototype.update = function () {
        if (this.currentLevelScore >= this.levelConfig.scoreToComplete) {
            console.log("Current level score:", this.currentLevelScore);
            console.log("Level score to complete:", this.levelConfig.scoreToComplete);
            this.updateGameState("CURRENT_LEVEL_COMPLETED");
        }
    };
    GameManager.prototype.advanceOneLevel = function () {
        this.incrementAllTimeScore(this.currentLevelScore);
        this.currentLevel += 1;
        if (this.currentLevel >= LEVELS.length) {
            this.updateGameState("GAME_COMPLETED");
            this.currentLevel = 0;
            return -1;
        }
        this.updateGameState("NEXT_LEVEL_READY");
        return this.currentLevel;
    };
    GameManager.prototype.startLevel = function () {
        this.updateGameState("IN_PROGRESS");
    };
    GameManager.prototype.resetLevel = function () {
        this.scene.scene.restart();
    };
    GameManager.prototype.getCurrentLevel = function () {
        return this.currentLevel;
    };
    GameManager.prototype.getLevelConfig = function () {
        return LEVELS[this.currentLevel];
    };
    Object.defineProperty(GameManager.prototype, "gameStatus", {
        get: function () {
            return {
                gameState: this.gameState,
                currentLevel: this.currentLevel,
                allTimeScore: this.allTimeScore,
                currentLevelScore: this.currentLevelScore,
            };
        },
        enumerable: false,
        configurable: true
    });
    GameManager.prototype.addPoints = function (points) {
        this.currentLevelScore += points;
        this.scene.events.emit(CUSTOM_EVENTS.CURRENT_LEVEL_SCORE_UPDATED, this.currentLevelScore);
    };
    GameManager.prototype.incrementAllTimeScore = function (number) {
        if (number === void 0) { number = 1; }
        this.allTimeScore += number;
        this.scene.events.emit(CUSTOM_EVENTS.ALL_TIME_SCORE_UPDATED, this.allTimeScore);
    };
    GameManager.prototype.updateGameState = function (newState) {
        var invalidTransitions = {
            IN_PROGRESS: [],
            PAUSED: [],
            CURRENT_LEVEL_COMPLETED: [],
            NEXT_LEVEL_READY: ["CURRENT_LEVEL_COMPLETED"],
            GAME_COMPLETED: ["CURRENT_LEVEL_COMPLETED"],
        };
        if (newState === this.gameState) {
            console.warn("Redundant attempt to update game state", newState);
            return;
        }
        if (invalidTransitions[this.gameState].includes(newState)) {
            console.warn("Invalid transition attempt from ".concat(this.gameState, " to ").concat(newState));
            return;
        }
        this.gameState = newState;
        console.log("Updating game state", newState);
        this.scene.events.emit(CUSTOM_EVENTS.GAME_STATE_UPDATED, this.gameState);
    };
    return GameManager;
}());
export default GameManager;
