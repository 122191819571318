import Phaser from "phaser";
import Ant from "./Ant";
import AntSpawner from "./AntSpawner";
import { CUSTOM_EVENTS } from "../config";
var AntManager = /** @class */ (function () {
    function AntManager(scene) {
        this.spawner = null;
        this.waypoints = [];
        this.validMovementArea = null;
        this.scene = scene;
    }
    AntManager.prototype.init = function (levelConfig) {
        this.waypoints = [];
        this.validMovementArea = null;
        this.ants = this.scene.physics.add.group({
            classType: Ant,
        });
        this.spawner = new AntSpawner(this.scene, this.ants, levelConfig.ants.spawnInterval, levelConfig.ants.spawnLocation, levelConfig.ants.speed);
    };
    AntManager.prototype.create = function (levelConfig) {
        this.createValidMovementArea(levelConfig);
    };
    AntManager.prototype.update = function () {
        var _this = this;
        if (!this.spawner || !this.ants)
            throw new Error("Must initialize spawner and ants before updating ants");
        this.spawner.update();
        this.ants.getChildren().forEach(function (ant) {
            if (!(ant instanceof Ant))
                throw new Error("Ant is null");
            ant.update(_this.waypoints);
        });
    };
    AntManager.prototype.createValidMovementArea = function (levelConfig) {
        var _this = this;
        if (this.validMovementArea) {
            return;
        }
        this.validMovementArea = this.scene.add
            .rectangle(this.scene.scale.width / 2, this.scene.scale.height / 2, this.scene.scale.width, this.scene.scale.height, 0xffffff, 0 // Make it invisible
        )
            .setInteractive();
        this.validMovementArea.on("pointerdown", function (pointer) {
            if (_this.waypoints.length < levelConfig.allowedWaypoints) {
                _this.addWaypoint(pointer);
            }
        });
    };
    AntManager.prototype.addWaypoint = function (pointer) {
        var waypoint = new Phaser.Math.Vector2(pointer.x, pointer.y);
        this.waypoints.push(waypoint);
        this.scene.add.image(waypoint.x, waypoint.y, "waypoint");
        this.scene.events.emit(CUSTOM_EVENTS.WAYPOINTS_UPDATED, this.waypoints);
    };
    AntManager.prototype.getAnts = function () {
        return this.ants;
    };
    AntManager.prototype.saveAnt = function (ant) {
        ant.destroy();
    };
    return AntManager;
}());
export default AntManager;
