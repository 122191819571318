// const screenWidth = GAME_CONFIG.width as number;
// const screenHeight = GAME_CONFIG.height as number;
var screenWidth = 600;
var screenHeight = 800;
export var LEVELS = [
    {
        allowedWaypoints: 2,
        scoreToComplete: 10,
        ants: {
            spawnInterval: 500,
            speed: 200,
            numberOfAnts: 10,
            spawnLocation: {
                x: screenWidth - screenWidth / 4,
                y: screenHeight / 10,
            },
        },
        obstacles: [
            {
                x: screenWidth / 2, // Center horizontally
                y: screenHeight / 2, // Center vertically
                width: 300,
                height: 10,
            },
        ],
    },
    {
        allowedWaypoints: 3,
        scoreToComplete: 20,
        ants: {
            spawnInterval: 500,
            speed: 200,
            numberOfAnts: 20,
            spawnLocation: {
                x: screenWidth - screenWidth / 4,
                y: screenHeight / 10,
            },
        },
        fireAnts: [
            { x: 200, y: 200, speed: 60 },
            { x: 300, y: 300, speed: 60 },
        ],
        obstacles: [
            {
                x: screenWidth / 2 - 100, // Center horizontally
                y: screenHeight / 2 - 50, // Center vertically
                width: 450,
                height: 10,
            },
            {
                x: screenWidth / 2 + 150, // Center horizontally
                y: screenHeight / 2 + 50, // Center vertically
                width: 450,
                height: 10,
            },
        ],
    },
];
