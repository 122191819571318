var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Phaser from "phaser";
import { ICON_KEYS } from "../config";
export var AntState;
(function (AntState) {
    AntState["MARCHING_TO_USER_WAYPOINT"] = "MARCHING_TO_USER_WAYPOINT";
    AntState["MARCHING_TO_EXIT"] = "MARCHING_TO_EXIT";
    AntState["TRACING_OBSTACLE"] = "TRACING_OBSTACLE";
    AntState["ATTACKING"] = "ATTACKING";
})(AntState || (AntState = {}));
var Ant = /** @class */ (function (_super) {
    __extends(Ant, _super);
    function Ant(scene, x, y, speed, health, antState) {
        if (speed === void 0) { speed = 100; }
        if (health === void 0) { health = 100; }
        if (antState === void 0) { antState = AntState.MARCHING_TO_EXIT; }
        var _this = _super.call(this, scene, x, y, ICON_KEYS.ANT) || this;
        _this._currentWaypointIndex = 0;
        _this.attackRange = 50;
        _this.attackDamage = 10;
        scene.add.existing(_this);
        scene.physics.add.existing(_this);
        _this.speed = speed;
        _this.health = health;
        _this.antState = antState;
        return _this;
    }
    Object.defineProperty(Ant.prototype, "currentWaypointIndex", {
        get: function () {
            return this._currentWaypointIndex;
        },
        set: function (value) {
            this._currentWaypointIndex = value;
        },
        enumerable: false,
        configurable: true
    });
    Ant.prototype.update = function (userWaypoints) {
        switch (this.antState) {
            case AntState.MARCHING_TO_USER_WAYPOINT:
                this.handleMarchingToUserWaypoint(userWaypoints);
                break;
            case AntState.MARCHING_TO_EXIT:
                this.handleMarchingToExit();
                break;
            // case AntState.ATTACKING:
            //   this.handleAttacking(enemies);
            //   break;
        }
        if (this.shouldMoveToAWaypoint(userWaypoints)) {
            this.antState = AntState.MARCHING_TO_USER_WAYPOINT;
        }
        if (this.shouldMoveToExit(userWaypoints)) {
            this.antState = AntState.MARCHING_TO_EXIT;
        }
    };
    Ant.prototype.handleMarchingToUserWaypoint = function (userWaypoints) {
        var targetWayPoint = userWaypoints[this.currentWaypointIndex];
        this.scene.physics.moveToObject(this, targetWayPoint, this.speed);
        if (this.isCloseToWaypoint(targetWayPoint)) {
            this.currentWaypointIndex += 1;
        }
    };
    Ant.prototype.handleAttacking = function (enemies) {
        var target = this.findClosestEnemy(enemies);
        if (target) {
            this.scene.physics.moveToObject(this, target, this.speed);
            if (this.isCloseToEnemy(target)) {
                this.attack(target);
            }
        }
    };
    Ant.prototype.findClosestEnemy = function (enemies) {
        var _this = this;
        var closestEnemy = null;
        var closestDistance = this.attackRange;
        enemies.forEach(function (enemy) {
            var distance = Phaser.Math.Distance.Between(_this.x, _this.y, enemy.x, enemy.y);
            if (distance < closestDistance) {
                closestEnemy = enemy;
                closestDistance = distance;
            }
        });
        return closestEnemy;
    };
    Ant.prototype.attack = function (target) {
        target.takeDamage(this.attackDamage);
    };
    Ant.prototype.takeDamage = function (amount) {
        this.health -= amount;
        if (this.health <= 0) {
            this.destroy();
        }
    };
    Ant.prototype.shouldMoveToExit = function (userWaypoints) {
        return this.currentWaypointIndex >= userWaypoints.length;
    };
    Ant.prototype.shouldMoveToAWaypoint = function (userWaypoints) {
        return (userWaypoints.length > 0 &&
            this.currentWaypointIndex < userWaypoints.length);
    };
    Ant.prototype.isCloseToWaypoint = function (targetWayPoint) {
        if (!this.body)
            throw new Error("Ant body is null");
        return (Phaser.Math.Distance.Between(this.body.center.x, this.body.center.y, targetWayPoint.x, targetWayPoint.y) < 10);
    };
    Ant.prototype.isCloseToEnemy = function (target) {
        if (!this.body || !target.body)
            throw new Error("Ant body is null");
        return (Phaser.Math.Distance.Between(this.body.center.x, this.body.center.y, target.body.center.x, target.body.center.y) < this.attackRange);
    };
    Ant.prototype.handleMarchingToExit = function () {
        this.setVelocityY(this.speed);
        this.setVelocityX(0);
    };
    return Ant;
}(Phaser.Physics.Arcade.Sprite));
export default Ant;
