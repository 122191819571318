var DebugUtility = /** @class */ (function () {
    function DebugUtility(scene) {
        this.debugText = null;
        this.debugMode = false;
        this.scene = scene;
    }
    DebugUtility.prototype.enableDebugMode = function () {
        this.debugMode = true;
        this.debugText = this.scene.add.text(10, 500, "", {
            font: "16px Arial",
            color: "rgba(0, 0, 0, 0.5)",
        });
    };
    DebugUtility.prototype.updateDebugInfo = function (instance) {
        if (!this.debugMode || !this.debugText)
            return;
        var properties = Object.keys(instance)
            .map(function (key) { return "".concat(key, ": ").concat(instance[key]); })
            .join("\n");
        this.debugText.setText(properties);
    };
    return DebugUtility;
}());
export default DebugUtility;
