import Ant from "./Ant";
var AntSpawner = /** @class */ (function () {
    function AntSpawner(scene, ants, spawnInterval, spawnLocation, antSpeed) {
        this.scene = scene;
        this.ants = ants;
        this.spawnInterval = spawnInterval;
        this.lastSpawnTime = 0;
        this.spawnLocation = spawnLocation;
        this.antSpeed = antSpeed;
    }
    AntSpawner.prototype.update = function () {
        var currentTime = this.scene.time.now;
        if (currentTime > this.lastSpawnTime + this.spawnInterval) {
            this.spawnAnt();
            this.lastSpawnTime = currentTime;
        }
    };
    AntSpawner.prototype.spawnAnt = function () {
        var ant = new Ant(this.scene, this.spawnLocation.x, this.spawnLocation.y, this.antSpeed);
        this.ants.add(ant);
    };
    return AntSpawner;
}());
export default AntSpawner;
