var CollisionManager = /** @class */ (function () {
    function CollisionManager(scene, antManager, environmentManager, gameManager, uiManager) {
        this.scene = scene;
        this.antManager = antManager;
        this.environmentManager = environmentManager;
        this.gameManager = gameManager;
        this.uiManager = uiManager;
    }
    CollisionManager.prototype.create = function () {
        var _this = this;
        this.scene.physics.add.collider(this.antManager.getAnts(), this.environmentManager.getObstacles());
        this.scene.physics.add.collider(this.antManager.getAnts(), this.environmentManager.getSafezones(), function (_, ant) {
            _this.antManager.saveAnt(ant);
            _this.gameManager.addPoints(1);
            _this.uiManager.updateProgressText(_this.gameManager.gameStatus.currentLevelScore);
        });
    };
    return CollisionManager;
}());
export default CollisionManager;
