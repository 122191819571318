import { CUSTOM_EVENTS, ICON_KEYS } from "../config";
var UIManager = /** @class */ (function () {
    function UIManager(scene, gameManager) {
        this.scene = scene;
        this.gameManager = gameManager;
        this.waypointsText = null;
        this.progressText = null;
        this.antIcon = null;
        this.waypointIcon = null;
    }
    UIManager.prototype.initForGamePlay = function (levelConfig) {
        var _this = this;
        this.waypointsText = null;
        this.progressText = null;
        this.antIcon = null;
        this.waypointIcon = null;
        this.scene.events.on(CUSTOM_EVENTS.CURRENT_LEVEL_SCORE_UPDATED, function (score) {
            _this.updateProgressText(score);
        });
        this.scene.events.on(CUSTOM_EVENTS.WAYPOINTS_UPDATED, function (waypoints) {
            _this.updateWaypoints(waypoints, levelConfig.allowedWaypoints);
        });
        this.scene.events.on(CUSTOM_EVENTS.GAME_STATE_UPDATED, function (state) {
            _this.handleGameStateChange(state);
        });
    };
    UIManager.prototype.createForGamePlay = function () {
        var _this = this;
        var levelConfig = this.gameManager.getLevelConfig();
        this.addProgressText(this.gameManager.gameStatus.currentLevelScore, levelConfig.scoreToComplete);
        this.addWaypointsText(levelConfig.allowedWaypoints);
        this.addInstructionText();
        this.createResetButton(function () { return _this.gameManager.resetLevel(); });
        this.createStartButton(function () { return _this.gameManager.startLevel(); });
    };
    UIManager.prototype.handleGameStateChange = function (state) {
        var _this = this;
        switch (state) {
            case "PAUSED":
                return;
            case "IN_PROGRESS":
                return;
            case "CURRENT_LEVEL_COMPLETED":
                this.createNextLevelButton(function () { return _this.gameManager.advanceOneLevel(); });
                return;
        }
    };
    UIManager.prototype.addWaypointsText = function (waypointLimit) {
        var container = this.scene.add.container(16, 48);
        var waypointIcon = this.scene.add
            .image(0, 0, ICON_KEYS.WAYPOINT)
            .setOrigin(0.5)
            .setScale(1.5);
        this.waypointsText = this.createText(24, 0, "".concat(waypointLimit), {
            fontSize: "12px",
            color: "#000",
        }).setOrigin(0.5);
        container.add([waypointIcon, this.waypointsText]);
    };
    UIManager.prototype.updateWaypoints = function (waypoints, waypointLimit) {
        var waypointsLeft = waypointLimit - waypoints.length;
        if (this.waypointsText) {
            this.waypointsText.setText("".concat(waypointsLeft));
        }
        else {
            console.warn("Waypoints text doesn't exist. Did you forget to call addWaypointsText()?");
        }
    };
    UIManager.prototype.addProgressText = function (currentScore, scoreToComplete) {
        var container = this.scene.add.container(16, 16);
        this.antIcon = this.scene.add
            .image(0, 0, ICON_KEYS.ANT)
            .setOrigin(0.5)
            .setScale(1.5);
        this.progressText = this.createText(24, 0, "".concat(currentScore, "/").concat(scoreToComplete), {
            fontSize: "12px",
            color: "#000",
        }).setOrigin(0.5);
        container.add([this.antIcon, this.progressText]);
    };
    UIManager.prototype.updateProgressText = function (currentScore) {
        var scoreToComplete = this.gameManager.getLevelConfig().scoreToComplete;
        if (this.progressText) {
            this.progressText.setText("".concat(currentScore, "/").concat(scoreToComplete));
        }
        else {
            console.warn("Progress text doesn't exist. Did you forget to call addProgressText()?");
        }
    };
    UIManager.prototype.addInstructionText = function () {
        this.scene.add
            .text(300, 700, "Tap anywhere to guide the ants!", {
            fontSize: "16px",
            color: "#000",
        })
            .setOrigin(0.5);
    };
    UIManager.prototype.createResetButton = function (callback) {
        this.createButton(32, 100, "Reset", callback, "Reset");
    };
    UIManager.prototype.createStartButton = function (callback) {
        var _this = this;
        this.createButton(32, 140, "Start", function () {
            callback();
            var startButton = _this.scene.children.getByName("Start");
            startButton === null || startButton === void 0 ? void 0 : startButton.destroy();
        }, "Start");
    };
    UIManager.prototype.createNextLevelButton = function (callback) {
        var _this = this;
        var _a = this.scene.scale, width = _a.width, height = _a.height;
        var nextLevelButton = this.createButton(width / 2, height / 2, "Next Level", function () {
            callback();
            var nextLevelButton = _this.scene.children.getByName("Next Level");
            nextLevelButton === null || nextLevelButton === void 0 ? void 0 : nextLevelButton.destroy();
        }, "Next Level");
    };
    // Create a generic text element
    UIManager.prototype.createText = function (x, y, text, style) {
        return this.scene.add.text(x, y, text, style).setOrigin(0.5);
    };
    // // Create a generic button element
    UIManager.prototype.createButton = function (x, y, text, callback, name) {
        var _this = this;
        if (name === void 0) { name = text; }
        var buttonText = this.createText(0, 0, text, {
            fontSize: "12px",
            color: "#000",
        });
        var buttonBackground = this.scene.add
            .rectangle(0, 0, buttonText.width + 20, buttonText.height + 20, 0xffffff)
            .setStrokeStyle(2, 0x000000);
        var buttonContainer = this.scene.add
            .container(x, y, [buttonBackground, buttonText])
            .setSize(buttonBackground.width, buttonBackground.height)
            .setInteractive()
            .on("pointerover", function () {
            return _this.handleButtonHover(buttonText, buttonBackground, true);
        })
            .on("pointerout", function () {
            return _this.handleButtonHover(buttonText, buttonBackground, false);
        })
            .on("pointerdown", callback);
        buttonContainer.setName(name);
        return buttonContainer;
    };
    UIManager.prototype.handleButtonHover = function (buttonText, buttonBackground, isHovering) {
        buttonText.setStyle({ fill: isHovering ? "#fff" : "#000" });
        buttonBackground.setFillStyle(isHovering ? 0x000000 : 0xffffff);
    };
    return UIManager;
}());
export default UIManager;
